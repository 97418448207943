import { useContext } from 'react'
import type { loader as RootLoader } from '~/root'

import {
  ArchiveBoxIcon,
  ArrowRightEndOnRectangleIcon,
  ArrowRightStartOnRectangleIcon,
  Cog8ToothIcon,
  CreditCardIcon,
  LightBulbIcon,
  PencilSquareIcon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
  TicketIcon,
  UserIcon,
  UserPlusIcon,
} from '@heroicons/react/16/solid'
import { Avatar } from '~/components/ui/avatar'
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from '~/components/ui/dropdown'
import {
  Navbar,
  NavbarItem,
  NavbarSection,
  NavbarSpacer,
} from '~/components/ui/navbar'

import {
  Link,
  isRouteErrorResponse,
  useLocation,
  useParams,
  useRouteError,
  useRouteLoaderData,
} from '@remix-run/react'

import { SearchPaletteContext } from '../contexts/SearchContext'

import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { LoungePairPlusLogo } from '~/components/loungepair-plus/Logo'
import LoungePairLogo from '~/components/svg-logos/LoungePairLogo'
import { shouldHideFeature, shouldShowFeature } from '~/config/features'
import type { BasicAccountWithRoles } from '~/models/accounts.server'
import * as accountUtils from '~/utils/accounts'
import { getSupportUrl } from '~/utils/utils'

export function Header() {
  if (shouldHideFeature('nav')) return false
  const params = useParams()
  const error = useRouteError()
  const root = useRouteLoaderData<typeof RootLoader>('root')
  const notLoggedIn = isRouteErrorResponse(error) || Boolean(!root?.account)
  const subscribed = accountUtils.hasActiveSubscription(root?.account)
  const isVerified = accountUtils.isVerified(root?.account)
  const canEdit = accountUtils.hasRole(root?.account, 'LP_ADMIN')
  const { setIsOpen } = useContext(SearchPaletteContext)

  // These have been implemented
  const shouldEdit = params.iata || params.slug
  function handleEdit(): string {
    if ('slug' in params) {
      return `/admin/resources/lounges?page=1&filters.slug=${params.slug}`
    }
    if ('iata' in params) {
      // edit airport
      return `/admin/resources/airports?page=1&filters.iata=${params.iata}`
    }
    if ('id' in params) {
      // edit order
    }
    return '/admin'
  }

  return (
    <Navbar
      className={clsx(
        'mb-4 sm:mb-8 mx-4 grid grid-cols-12 border-b-2',
        subscribed ? null : 'mb-4 sm:pb-8',
      )}
    >
      <Link to="/directory/" aria-label="Home" className="col-span-4">
        {subscribed ? (
          <LoungePairPlusLogo className="w-48" />
        ) : (
          <LoungePairLogo className="w-36 sm:w-48 text-stone-800" />
        )}
      </Link>
      <NavbarSection className="justify-end col-span-8">
        {canEdit && shouldEdit ? (
          <NavbarItem
            target="_blank"
            href={handleEdit()}
            aria-label="Edit Page"
          >
            <PencilSquareIcon
              className="w-5 h-5 text-stone-800"
              aria-hidden="true"
            />
          </NavbarItem>
        ) : null}

        {shouldShowFeature('nav.search') ? (
          <NavbarItem onClick={() => setIsOpen(true)} aria-label="Edit Page">
            <MagnifyingGlassIcon
              className="w-5 h-5 text-stone-800"
              aria-hidden="true"
            />
          </NavbarItem>
        ) : null}
        {shouldShowFeature('nav.accounts') ? (
          <AccountMenu
            isSubscribed={subscribed}
            notLoggedIn={notLoggedIn}
            account={root?.account}
          />
        ) : null}
      </NavbarSection>
    </Navbar>
  )
}

export function AccountMenu({
  notLoggedIn,
  isSubscribed,
  account,
}: {
  notLoggedIn: boolean
  isSubscribed: boolean
  account?: BasicAccountWithRoles | null
}) {
  const currentUrl = useLocation()
  const supportUrl = getSupportUrl()

  if (notLoggedIn) {
    return (
      <Dropdown>
        <DropdownButton as={NavbarItem} aria-label="Account menu">
          <span className="inline-block w-6 h-6 overflow-hidden rounded-full cursor-pointer bg-stone-50">
            <svg
              className="w-full h-full text-stone-600"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          </span>
        </DropdownButton>
        <DropdownMenu className="min-w-64 z-10" anchor="bottom end">
          <DropdownItem href="/account/login">
            <ArrowRightEndOnRectangleIcon />
            <DropdownLabel>Account Login</DropdownLabel>
          </DropdownItem>
          <DropdownItem href="/account/signup">
            <UserPlusIcon />
            <DropdownLabel>Create Account</DropdownLabel>
          </DropdownItem>
          <DropdownDivider />
          <DropdownItem href="https://www.loungepair.com/privacy-policy">
            <ShieldCheckIcon />
            <DropdownLabel>Privacy policy</DropdownLabel>
          </DropdownItem>
          <DropdownItem href="https://helpdesk.loungepair.com/help/3983236506">
            <LightBulbIcon />
            <DropdownLabel>Share feedback</DropdownLabel>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    )
  }

  return (
    <Dropdown>
      <DropdownButton as={NavbarItem} aria-label="Account menu">
        {/* <Avatar initials={root.account.initials} alt="User avatar" /> */}
        <span className="inline-block w-6 h-6 overflow-hidden rounded-full bg-stone-50">
          <svg
            className="w-full h-full text-stone-600"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </span>
      </DropdownButton>
      <DropdownMenu className="min-w-64 z-10" anchor="bottom end">
        <DropdownItem href="/account/orders">
          <UserIcon />
          <DropdownLabel>
            My LoungePair {account?.email ? `(${account?.email})` : ''}
          </DropdownLabel>
        </DropdownItem>
        {isSubscribed ? (
          <DropdownItem href="/account/billing">
            <CreditCardIcon />
            <DropdownLabel>Manage Subscription</DropdownLabel>
          </DropdownItem>
        ) : (
          <DropdownItem href="/account/billing">
            <CreditCardIcon />
            <DropdownLabel>Join LoungePair+</DropdownLabel>
          </DropdownItem>
        )}
        <DropdownDivider />
        <DropdownItem rel="nofollow" href={supportUrl}>
          <QuestionMarkCircleIcon />
          <DropdownLabel>Get Support</DropdownLabel>
        </DropdownItem>
        <DropdownDivider />
        <DropdownItem
          href={`/account/logout?redirectTo=${currentUrl.pathname}`}
        >
          <ArrowRightStartOnRectangleIcon />
          <DropdownLabel>Log out</DropdownLabel>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}
